<template>
  <div>
    <div class="header-box"
         :style="isActive != '/basehome' || isActive != '/Feature' ?'background: #7BBE60;':changeColor">
      <div class="center-box">
        <div class="logo-img cur">
          <a @click="RouterTo(TabMenu[0])">
            <img src="../assets/images/md/logo.png"
                 alt="">
          </a>
        </div>
        <div class="tab-list-box">
          <div v-for="(item,index) in TabMenu"
               :key="index"
               :class="item.link == isActive?'isActice':''">
            <a @click="RouterTo(item)">{{item.name}}</a>
          </div>
        </div>
        <div v-if="!userName"
             class="tab-btn">
          <div @click="toRegister('/register')">注册</div>
          <div class="login-link"
               @click="toRegister('/login')">
            <img src="../assets/images/pc/user.png"
                 alt="">
            <span>{{userName?userName:'登录'}}</span>
          </div>
        </div>
        <div v-else
             class="tab-btn">
          <div v-if="!userName"
               @click="toRegister('/register')">注册</div>
          <el-dropdown @command="LoginOut">
            <div class="login-link">
              <img src="../assets/images/pc/user.png"
                   alt="">
              <span>{{userName?userName:'登录'}}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- content -->
    <router-view></router-view>
    <!-- footer -->
    <div class="footer-box">
      <div class="center-box">
        <div style="overflow: hidden;">
          <div class="about-box">
            <div>联系我们</div>
            <div class="about-code">
              <div class="code-list">
                <img :src="require('../assets/images/pc/footerCode1.png')"
                     alt="">
                <div>扫码关注公众号</div>
              </div>
              <div class="code-list">
                <img :src="require('../assets/images/pc/footerCode2.png')"
                     alt="">
                <div>扫码关注抖音号</div>
              </div>
            </div>
            <div class="about-tel">
              <div>服务热线：0731-85118243</div>
              <div>联系地址：长沙市岳麓区高新区麓龙路麓谷坐标F栋</div>
            </div>
          </div>
          <div class="icon-box">
            <div class="icon-list"
                 v-for="(item,index) in Footer"
                 :key="index">
              <img :src="item.imgUrl"
                   alt="">
              <div>{{item.desc}}</div>
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          <div class="footer-link">
            <a v-for="(item,index) in TabMenu"
               :key="index"
               @click="RouterTo(item)">{{item.name}}</a>
          </div>
          <div> <a href="https://beian.miit.gov.cn"
               target="_blank">湘ICP备2023012924号</a> - 法律声明</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isActive: window.sessionStorage.getItem('routerName'),
      userName: window.sessionStorage.getItem('userName'),
      scroll: "",
      percent: "",
      TabMenu: [
        { name: '首页', link: '/basehome', isActive: true },
        { name: '产品功能', link: '/Feature', isActive: false },
        { name: '关于我们', link: '/aboutUs', isActive: false },
        { name: '下载软件', link: '/download', isActive: false },
      ],
      Footer: [
        { imgUrl: require('../assets/images/pc/footer1.png'), desc: '海豚管家' },
        { imgUrl: require('../assets/images/pc/footer2.png'), desc: '云服务管理' },
        { imgUrl: require('../assets/images/pc/footer3.png'), desc: '聊天台' },
        { imgUrl: require('../assets/images/pc/footer4.png'), desc: '售后服务' },
        { imgUrl: require('../assets/images/pc/footer5.png'), desc: '使用教程' },
        { imgUrl: require('../assets/images/pc/footer6.png'), desc: 'API对接' },
      ],
    }
  },
  mounted () {
    window.addEventListener("scroll", this.menu)
    window.addEventListener("setItem", (e) => {
      if (e.key == 'routerName') {
        this.isActive = e.newValue
      }
    })
  },
  // watch: {
  //   isActive (val) {
  //     console.log(val)
  //   },
  // },
  computed: {
    changeColor () {
      let percent = this.percent;
      return {
        'background-color': `rgba(123, 190, 96,  ${percent})`,
      };
    },
  },
  created () {
    if (this.userName) {
      const obj = { name: '云服务', link: '/yunServices', isActive: false }
      this.TabMenu.splice(2, 0, obj);
    }
  },
  methods: {
    menu () {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
      if (this.scroll < 250) {
        this.percent = (this.scroll / 250);
      } else {
        this.percent = 1;
      }
    },
    // 菜单
    RouterTo (menu) {
      if (this.isActive == menu.link) return
      this.isActive = menu.link
      this.$router.push(menu.link)
      // window.sessionStorage.setItem('routerName', menu.link)
      this.setSessionItem('routerName', menu.link)
    },
    // 登录、注册
    toRegister (RouteUrl) {
      if (this.isActive == RouteUrl) return
      this.isActive = RouteUrl
      this.$router.push(RouteUrl)
      // window.sessionStorage.setItem('routerName', RouteUrl)
      this.setSessionItem('routerName', RouteUrl)
    },
    LoginOut () {
      window.sessionStorage.clear()
      this.TabMenu.splice(2, 1);
      this.isActive = ""
      this.userName = ""
      this.$router.push('/login')
      // location.reload()
    },
  }
}
</script>

<style lang="less" scoped>
</style>
